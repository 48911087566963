import * as React from "react"
import { addPropertyControls, ControlType } from "framer"
import { motion } from "framer-motion"

/**
 * @framerDisableUnlink
 * @framerIntrinsicWidth 150
 * @framerIntrinsicHeight 50
 */

export default function Button9x(props) {
    const {
        text,
        link,
        newTab,
        buttonAction,
        tallyId,
        tallyWidth,
        calLink,
        dataAttributes,
        variant,
        trackClick,
        trackCategory,
        trackProduct,
        trackElement,
        trackAction,
    } = props

    const textVariants = {
        initial: {
            fontWeight: "500",
        },
        hover: {
            fontWeight: "700",
        },
    }
    let boxshadow = "2px 2px 0px 0px #0F1E35"
    let hoverBoxshadow = "6px 6px 0px 0px #0F1E35"

    let buttonStyle = {
        display: "flex",
        padding: "8px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "5px",
        border: "2px solid #0F1E35",
        cursor: "pointer",
        textDecoration: "none",
    }

    let textStyle = {
        textAlign: "center",
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        lineHeight: "125%",
    }

    switch (variant) {
        case "white":
            buttonStyle.background = "#FFF"
            textStyle.color = "#0F1E35"
            break
        case "boring-blue":
            buttonStyle.background = "#3f4b5d"
            textStyle.color = "#FFF"
            break
        case "flashy-yellow":
            buttonStyle.background = "#FFCA51"
            textStyle.color = "#0F1E35"
            break
        case "flashy-orange":
            buttonStyle.background = "#FF6D33"
            textStyle.color = "#FFF"
            break
        case "white-boring-blue-shadow":
            buttonStyle.background = "#FFF"
            buttonStyle.border = "2px solid #000"
            textStyle.color = "#0f1e35"
            boxshadow = "2px 2px 0px 0px #000"
            hoverBoxshadow = "6px 6px 0px 0px #000"
            break
        case "flashy-yellow-boring-blue-shadow":
            buttonStyle.background = "#FFCA51"
            buttonStyle.border = "2px solid #000"
            textStyle.color = "#0F1E35"
            boxshadow = "2px 2px 0px 0px #000"
            hoverBoxshadow = "6px 6px 0px 0px #000"
            break
        default:
            buttonStyle.background = "#FF6D33"
            textStyle.color = "#FFF"
            break
    }

    let buttonVariants = {
        initial: {
            boxShadow: boxshadow,
        },
        hover: {
            boxShadow: hoverBoxshadow,
        },
    }

    const dataAttrs = {}
    if (dataAttributes) {
        dataAttributes.forEach((attr) => {
            if (attr.key && attr.value) {
                dataAttrs[`data-${attr.key}`] = attr.value
            }
        })
    }

    if (buttonAction === "tally") {
        dataAttrs["data-tally-open"] = tallyId
        dataAttrs["data-tally-layout"] = "modal"
        dataAttrs["data-tally-width"] = tallyWidth
    }

    if (buttonAction === "cal") {
        let elementParam = ""
        let categoryParam = ""
        let productParam = ""
        if (trackElement) {
            elementParam = "&source-element=" + trackElement
        }
        if (trackCategory) {
            categoryParam = "&source-category=" + trackCategory
        }
        if (trackProduct) {
            productParam = "&source-product=" + trackProduct
        }
        const joinedParams = elementParam + categoryParam + productParam
        const calParams = joinedParams.replace("&", "?")

        dataAttrs["data-nx-cal-link"] = calLink + calParams
        dataAttrs["data-cal-link"] = calLink + calParams
    }

    const handleClick = () => {
        if (trackClick) {
            const eventProperties = {
                cta_type: trackAction,
                product_category: trackCategory,
            }

            if (trackProduct) {
                eventProperties.product_type = trackProduct
            }
            if (trackElement) {
                eventProperties.element = trackElement
            }

            rudderanalytics.track("cta_click", eventProperties)
        }
    }

    return (
        <motion.a
            href={buttonAction === "link" ? link : undefined}
            target={newTab ? "_blank" : "_self"}
            style={buttonStyle}
            variants={buttonVariants}
            initial="initial"
            whileHover="hover"
            onClick={handleClick}
            {...dataAttrs}
        >
            <motion.span style={textStyle} variants={textVariants}>
                {text}
            </motion.span>
        </motion.a>
    )
}

Button9x.defaultProps = {
    text: "Button",
    link: "#",
    dataAttributes: [],
    variant: "flashy-orange",
    buttonAction: "link",
    tallyId: "",
    tallyWidth: 600,
    calLink: "team/9x/quick-chat",
    newTab: false,
    trackClick: false,
    trackCategory: "learning",
    trackProduct: "",
    trackElement: "",
    trackAction: "book-call",
}

addPropertyControls(Button9x, {
    variant: {
        type: ControlType.Enum,
        title: "Variant",
        options: [
            "flashy-orange",
            "white",
            "boring-blue",
            "flashy-yellow",
            "white-boring-blue-shadow",
            "flashy-yellow-boring-blue-shadow",
        ],
        optionTitles: [
            "Flashy Orange",
            "White",
            "Boring Blue",
            "Flashy Yellow",
            "White - Boring Blue Shadow",
            "Flashy Yellow - Boring Blue Shadow",
        ],
        defaultValue: "flashy-yellow",
    },
    text: {
        type: ControlType.String,
        title: "Button Text",
        defaultValue: "Get started",
    },
    buttonAction: {
        type: ControlType.Enum,
        title: "Button Action",
        options: ["link", "tally", "cal"],
        optionTitles: ["Link", "Tally Popup", "Cal.com Popup"],
        defaultValue: "link",
    },
    link: {
        type: ControlType.String,
        title: "Link",
        defaultValue: "#",
        hidden: (props) => props.buttonAction !== "link",
    },
    newTab: {
        type: ControlType.Boolean,
        title: "New Tab",
        defaultValue: false,
        hidden: (props) => props.buttonAction !== "link",
    },
    tallyId: {
        type: ControlType.String,
        title: "Tally ID",
        defaultValue: "",
        hidden: (props) => props.buttonAction !== "tally",
    },
    tallyWidth: {
        type: ControlType.Number,
        title: "Tally Modal Width",
        defaultValue: 600,
        min: 0,
        unit: "px",
        hidden: (props) => props.buttonAction !== "tally",
    },
    calLink: {
        type: ControlType.String,
        title: "Cal.com Link",
        defaultValue: "team/9x/quick-chat",
        hidden: (props) => props.buttonAction !== "cal",
    },
    trackClick: {
        type: ControlType.Boolean,
        title: "Track Click",
        defaultValue: false,
    },
    trackAction: {
        type: ControlType.Enum,
        title: "Track Action",
        options: [
            "book-call",
            "enrol-course",
            "purchase-course",
            "join-waitlist",
            "scroll",
            "contact",
            "login",
        ],
        optionTitles: [
            "Book Call",
            "Enrol Course",
            "Purchase Course",
            "Join Waitlist",
            "Scroll",
            "Contact",
            "Login",
        ],
        defaultValue: "book-call",
        hidden: (props) => !props.trackClick,
    },
    trackCategory: {
        type: ControlType.Enum,
        title: "Track Category",
        options: ["learning", "advisory", "general"],
        optionTitles: ["Learning", "Advisory", "General"],
        defaultValue: "learning",
        hidden: (props) => !props.trackClick,
    },
    trackElement: {
        type: ControlType.String,
        title: "Track Element",
        defaultValue: "",
        hidden: (props) => !props.trackClick,
    },
    trackProduct: {
        type: ControlType.String,
        title: "Track Product",
        defaultValue: "",
        hidden: (props) => !props.trackClick,
    },
    dataAttributes: {
        type: ControlType.Array,
        title: "Data Attributes",
        control: {
            type: ControlType.Object,
            controls: {
                key: { type: ControlType.String, title: "Key" },
                value: { type: ControlType.String, title: "Value" },
            },
        },
    },
})
